import { Component, Host, Prop, Element, Listen, h } from '@stencil/core';
import axios from 'axios';
import { paginationStore } from '../../stores';

@Component({
  tag: 'x-pagination-context',
})
export class XPaginationContext {
  @Element() el: HTMLElement;

  @Prop({ mutable: true, reflect: true }) currentPage: number;
  @Prop({ mutable: true, reflect: true }) pageSize: number;
  @Prop({ mutable: true, reflect: true }) totalPages: number;
  @Prop({ mutable: true, reflect: true }) totalRecords: number;
  @Prop() endpoint: string;
  @Prop() target: boolean = false;

  @Listen('refresh')
  update() {
    if (this.target) {
      return;
    }

    paginationStore.set('isLoading', true);

    axios
      .get(this.endpoint, {
        params: {
          'page-number': paginationStore.get('currentPage', this.currentPage),
          'page-size': paginationStore.get('pageSize', this.pageSize),
        },
      })
      .then(res => {
        this.el.querySelector('x-pagination-context[target]').innerHTML = res.data;

        const nextCurrent = res.headers['x-current-page'];
        const nextSize = res.headers['x-page-size'];
        const nextTotal = res.headers['x-total-pages'];
        const nextTotalRecords = res.headers['x-total-records'];

        if (nextCurrent) paginationStore.set('currentPage', parseInt(nextCurrent, 10));
        if (nextSize) paginationStore.set('pageSize', parseInt(nextSize, 10));
        if (nextTotal) paginationStore.set('totalPages', parseInt(nextTotal, 10));
        if (nextTotalRecords) paginationStore.set('totalRecords', parseInt(nextTotalRecords, 10));
      })
      .finally(() => {
        paginationStore.set('isLoading', false);
      });
  }

  connectedCallback() {
    if (this.target) {
      return;
    }

    paginationStore.set('isLoading', false);
    paginationStore.set('currentPage', this.currentPage);
    paginationStore.set('pageSize', this.pageSize);
    paginationStore.set('totalPages', this.totalPages);
    paginationStore.set('totalRecords', this.totalRecords);

    paginationStore.use({
      set: (key, newValue) => {
        this[key] = newValue;

        if (paginationStore.get('isLoading') === false && (key === 'currentPage' || key === 'pageSize')) {
          this.update();
        }
      },
    });
  }

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
